<template>
  <QDialog
    v-model="show"
    persistent
    full-width
  >
    <QCard
      flat
      class="grid md:(grid-cols-2 px-40) lg:(grid-cols-3 px-40) gap-5 px-2 bg-transparent"
    >
      <div
        v-for="(advertisement,index) in advertisements.slice(0, showCount)"
        class="w-full"
      >
        <QCard
          v-show="!advertisement.hide"
          class="w-full"
        >
          <QBtn
            icon="close"
            color="primary"
            unelevated
            square
            dense
            text-color="white"
            class="absolute top-0 right-0 z-50"
            @click="closeAdvertisement(index)"
          />
          <QImg
            :src="advertisement.image.url"
          />
        </QCard>
      </div>
    </QCard>
  </QDialog>
</template>

<script setup>
import { api } from '@/plugins/axios.js'
import { useQuasar } from 'quasar'
import { computed, ref } from 'vue'
import { dayjs } from '@/utils'

const $q = useQuasar()

const show = ref(false)
const advertisements = ref([])

const showCount = computed(() => {
  switch ($q.screen.name) {
    case 'lg':
    case 'xl':
      return 3
    case 'md':
      return 2
    default:
      return 1
  }
})

let lastShowDate = window.localStorage.getItem('advertisements_last_show_date')
if (dayjs().format('YYYY-MM-DD') !== lastShowDate) {
  showAdvertisement()
  window.localStorage.setItem('advertisements_last_show_date', dayjs().format('YYYY-MM-DD'))
}

async function showAdvertisement () {
  const { data } = await api.get('advertisements')
  if (data.data.length > 0) {
    advertisements.value = data.data
    show.value = true
  }
}

function closeAdvertisement (index) {
  if (advertisements.value.length > showCount.value) {
    advertisements.value.splice(index, 1)
    advertisements.value.unshift(advertisements.value.shift())
  } else {
    advertisements.value[index].hide = true
  }

  if (advertisements.value.every(ad=>ad.hide)) {
    show.value = false
    // window.localStorage.setItem('advertisements_last_shown_date', dayjs().format('YYYY-MM-DD'))
  }
}
</script>
