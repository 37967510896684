import { useTitle } from '@vueuse/core'
import { createApp } from 'vue'
import router from './router'
import store from './stores'
import echo from './plugins/echo'
import quasar from './plugins/quasar'
import icons from './plugins/icon.js'
import 'uno.css'
import '@unocss/reset/tailwind.css'
import './css/app.css'
import { setGlobalOptions } from 'vue-request'

import App from './App.vue'

setGlobalOptions({
  manual: true,
})

const app = createApp(App)
// app.config.performance=true
app.use(router)
app.use(store)
app.use(echo)
app.use(quasar)
app.use(icons, {
  style: 'filled' // filled  colorful outlined
})

app.mount('#app')

useTitle(import.meta.env.VITE_APP_NAME)
